import { Link, PageProps } from "gatsby"
import React from "react"
import "twin.macro"
import { Layout, NextSEO, SEO } from "@/components"
import { Article, Headings, Section, Typography } from "@/atoms"
import tw from "twin.macro"
import { BiError } from "@react-icons/all-files/bi/BiError";

export type ErrorPageProps = PageProps

const ErrorPage: React.FC<ErrorPageProps> = () => {
  return (
    <Layout>
      {/* <SEO
        meta={[
          {
            name: "robots",
            content: "noindex,nofollow",
          },
        ]}
      /> */}
      <NextSEO
        type="Others"
        title="存在しないページ"
        description="存在しないページ"
        url="/404"
        noindex
        nofollow
        breadCrumbs={[{ title: '存在しないページ' }]}
      />
      <Article maxWidth={'tablet'} css={{ ...tw`space-y-12 py-12` }}>
        <Section css={{ ...tw`flex flex-col items-center justify-center space-y-2` }}>
          <BiError css={{ ...tw`text-7xl` }} />
          <header>
            <Headings variant="h1">404 not found</Headings>
          </header>
          <Typography>
            <p>存在しないページです</p>
          </Typography>
        </Section>
        <Section css={{ ...tw`flex flex-col items-center justify-center space-y-2` }}>
          <header>
            <Headings variant="h5" as='h1'>他のページへ戻る</Headings>
          </header>
          <ul>
            <li>
              <Link to='/'>ホーム</Link>
            </li>
          </ul>
        </Section>
      </Article>
    </Layout>
  )
}

export default ErrorPage
